import AsyncLock from 'async-lock'
import fs from 'fs'
import path from 'path'

export default class CachedFetcher {
    constructor(cacheDir, disableFetch = false) {
        this.cacheDir = cacheDir;
        fs.mkdirSync(cacheDir, { recursive: true });

        this.lock = new AsyncLock();
        this.disableFetch = disableFetch;
    }

    fileExists(f) {
        try {
            fs.statSync(f);
            return true;
        } catch (err) {
            return false;
        }
    }
    idForURL(url) {
        let id = "";
        for (let match of url.toLowerCase().matchAll("(?<text>[A-z0-9]+)")) {
            id += match.groups["text"];
        }
        if (id.length > 200) {
            id = id.substr(0, 200);
        }
        return id;
    }
    async fetch(url) {
        let id = this.idForURL(url);

        const cacheFile = path.join(this.cacheDir, id);
        if (this.fileExists(cacheFile)) {
            return Promise.resolve(fs.readFileSync(cacheFile).toString());
        }
        if (this.disableFetch) {
            throw "fetch disabled: " + url;
        }
        return this.lock.acquire("cachedFetch", async () => {
            return new Promise((resolve, reject) => {
                request(url, (e, response, body) => {
                    if (response.statusCode !== 200) {
                        reject("err: " + response.statusCode + " " + url);
                        return;
                    }
                    fs.writeFileSync(cacheFile, body);
                    resolve(body);
                });
            }).then(async body => {
                await sleep(400);
                return body;
            });
        });
    }
}
