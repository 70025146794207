import React, { useState, useRef, useEffect } from "react";

const EditableText = ({ className, children, defaultValue, onSubmit }) => {
    const [lastKnownDefault, setLastKnownDefault] = useState(defaultValue);
    const [editingComment, setEditingComment] = useState(false);

    useEffect(() => {
        if (editingComment) {
            // We just displayed, so focus on the textbox.
            inputCommentRef.current.select();
        }
    }, [editingComment]);

    const [comment, setComment] = useState(defaultValue);
    const inputCommentRef = useRef();

    const onClickedParentDiv = (e) => {
        if (!editingComment) {
            setEditingComment(true);
        }
    };
    const onChangeComment = (e) => setComment(e.target.value);
    const onSubmitInternal =
        (e) => {
            if (comment !== lastKnownDefault) {
                console.log("sending to onSubmit:", comment);
                onSubmit(comment);
                setLastKnownDefault(comment);
            }
            if (editingComment) {
                setEditingComment(false);
            }
            if (e) {
                e.preventDefault();
            }
        };

    const onKeyDown = (e) => {
        if (e.key === "Escape") {
            setComment(lastKnownDefault);
            setEditingComment(false);
        }
    };
    const onBlur = (e) => {
        setEditingComment(false);
        onSubmitInternal();
        e.stopPropagation();
        e.preventDefault();
    };

    return <div
        className={className}
        onMouseDown={onClickedParentDiv}
    >
        {children}
        {
            editingComment ?
                <form
                    onSubmit={onSubmitInternal}
                    className="VerdictForm">
                    <input
                        ref={inputCommentRef}
                        value={comment}
                        onChange={onChangeComment}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                    />
                </form >
                : <div className="VerdictCommentText">{comment}</div>
        }
    </div>;
};
export default EditableText;
