import './App.css';

import React, { useState } from "react";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import { ToastContextProvider } from "./toast.js";
import firebase from "firebase/app";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Paper, Tab, Tabs } from '@material-ui/core';
import { TwitterFollowButton } from 'react-twitter-embed';

import { FirebaseAuthContextProvider, useAuth } from "./firebase_auth_wth_claims.js";
import ArticlesPane from "./articles.js";
import ArticleLeafPage from "./article_leaf.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const PageDescription = (props) => {
  return <div className="PageDescription">
    <Typography variant="body1">
      虚構のようで現実のニュースを紹介するサイトです。SNSで「虚構かと思った」のようなコメントが沢山ついている記事を紹介します。
    </Typography>
    <TwitterFollowButton screenName={'kyoko_janai'} />
  </div>;
};

const TopPage = ({ isAdminPage }) => {
  const classes = useStyles();

  const auth = useAuth();
  const isAdmin = isAdminPage && auth.isSignedIn && auth.claims.role === "admin";

  let loginButtons = "";
  if (isAdminPage) {
    loginButtons = (<div>
      <Button
        color="inherit"
        // variant="contained"
        onClick={() => {
          const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
          firebase.auth().signInWithPopup(googleAuthProvider);
        }}
      >
        {auth.isSignedIn ? "Sign In with a different user" : "Sign in with Google"}
      </Button>
      {
        auth.isSignedIn ?
          <Button
            color="inherit"
            // variant="contained"
            onClick={() => {
              firebase.auth().signOut();
            }}
          >
            Sign Out
        </Button>
          : ""
      }
      { auth.user ?
        <Typography variant="h6">
          {"Hello " + auth.user.displayName}
        </Typography>
        : ""}
    </div>);
  }

  const [selectedTab, setSelectedTab] = useState(0);
  const onTabChange = (e, newValue) => {
    setSelectedTab(newValue);
  };
  const tab = isAdmin ?
    <Paper square>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={onTabChange}
        aria-label="admin page tabs"
      >
        <Tab label="承認が必要な記事" />
        <Tab label="ツイート済みの記事" />
        <Tab label="すべての記事" />
      </Tabs>
    </Paper>
    : "";

  let articlePage;
  if (!isAdmin) {
    articlePage = "allTweeted";
  } else if (selectedTab === 0) {
    articlePage = "needsVerdict";
  } else if (selectedTab === 1) {
    articlePage = "allTweeted";
  } else if (selectedTab === 2) {
    articlePage = "allArticles";
  } else {
    return <div>Woops</div>;
  }

  return <div>
    <AppBar position="static" color="default">
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          虚構じゃないニュース
          </Typography>
        {loginButtons}
      </Toolbar>
    </AppBar>

    {tab}

    <PageDescription />

    <ArticlesPane isAdmin={isAdmin} articlePage={articlePage}></ArticlesPane>
    <hr />
    {isAdminPage ? <a href="/">Back to top</a> : ""}
  </div >;
}

const HomePage = (props) => {
  return <TopPage isAdminPage={false}></TopPage>
}
const AdminPage = (props) => {
  return <TopPage isAdminPage={true}></TopPage>
}

const EnvPage = (props) => {
  const auth = useAuth();
  const isAdmin = auth.isSignedIn && auth.claims.role === "admin";
  if (!isAdmin) {
    return <div></div>;
  }

  return <div>
    {Object.keys(process.env).map(k => <div>{k}={process.env[k]}</div>)}
  </div>;
}

const ArticleLeafFromRoute = (props) => {
  const { articleId } = useParams();
  return <ArticleLeafPage articleId={articleId} />
};

function App() {
  return (
    <div className="App">
      <ToastContextProvider>
        <FirebaseAuthContextProvider>
          <Router>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/article/:articleId" component={ArticleLeafFromRoute} />
            <Route exact path="/admin" component={AdminPage} />
            {/* Debug. */}
            <Route exact path="/dotenvz" component={EnvPage} />
          </Router>
        </FirebaseAuthContextProvider>
      </ToastContextProvider>
    </div>
  );
}

export default App;
