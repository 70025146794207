
import firebase from "firebase";
import common from "kyokocommon";
import firebaseConfig from "./firebase_config.js";

export function initializeFirestore() {
    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
        if (process.env.NODE_ENV === "development") {
            // firebase.firestore().useEmulator("localhost", 8080);
            // firebase.auth().useEmulator('http://localhost:9099/');
        }
    }
    return firebase.firestore();
}
class ArticleDb extends common.models.BaseArticleDb {
    constructor() {
        super(initializeFirestore());
    }
}
export default new ArticleDb();
