const firebaseConfig = {
    apiKey: "AIzaSyDoaeBBDyKfIEEB9Q2n-pjgz4EgRby4oDY",
    authDomain: "kyoko-janai.firebaseapp.com",
    projectId: "kyoko-janai",
    storageBucket: "kyoko-janai.appspot.com",
    messagingSenderId: "302977174335",
    appId: "1:302977174335:web:afa9fb43f0ea2ebd3ae18a",
    measurementId: "G-ZM82FMR7WN"
};
export default firebaseConfig;
