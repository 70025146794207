import Twitter2 from "twitter-v2";
import "../utils/setup_dotenv.js";
import { toMap, uniquify } from "../utils/arrays.js";

let twitterClient;
function client() {
    if (!twitterClient) {
        twitterClient = new Twitter2({
            consumer_key: process.env.TWITTER_CONSUMER_KEY,
            consumer_secret: process.env.TWITTER_CONSUMER_SECRET_KEY,
            bearer_token: process.env.TWITTER_BEARER_TOKEN,
            // access_token_key: process.env.TWITTER_ACCESS_TOKEY_KEY,
            // access_token_secret: process.env.TWITTER_ACCESS_TOKEN_SECRET,
        });
    }
    return twitterClient;
}

// const cacheDir = "tmp";
// fs.mkdirSync(cacheDir, { recursive: true });

// let cachedRelatedSearchFetcher = new CachedMap(
//     path.join(cacheDir, "recentTweetSearch.json"),
//     async (id) => {
//         console.log("do fetch!");
//         return client.get('tweets/search/recent', JSON.parse(id));
//     }
// );
async function doRelatedSearch(since_id, limit = 100) {
    // Search API: https://developer.twitter.com/en/docs/twitter-api/tweets/search/api-reference/get-tweets-search-recent
    let request = {
        query: '虚構新聞 -@kenkawakenkenke -@kyoko_janai -is:retweet (has:links OR is:reply)',
        max_results: limit,
        tweet: {
            fields: [
                'created_at',
                'entities',
                'in_reply_to_user_id',
                'public_metrics',
                'referenced_tweets',
                'source',
                'author_id',
            ],
        },
        user: {
            fields: [
                'name',
                'username',
                'description',
            ],
        },
        media: {
            fields: [
                'preview_image_url',
            ]
        },
        expansions: [
            "author_id",
            "referenced_tweets.id",
        ],
    };
    if (since_id) {
        request.since_id = since_id;
    }
    return client().get('tweets/search/recent', request);
    // return cachedRelatedSearchFetcher.getValue(JSON.stringify(request));
}

function urlForTweet(id) {
    return `https://twitter.com/_/status/${id}`;
}
function idForTweet(statusUrl) {
    const match = statusUrl.match(/\/status\/([0-9]*)$/);
    return match ? match[1] : undefined;
}

// Returns true for articles that are disallowed.
// TODO: if you update this, update the one for hatena too.
const disallowedDomains = [
    "anond\\.hatelabo\\.jp",
    "togetter\\.com",
    "note\\.com",
    "peing\\.net",
    "twitter\\.com",
    "pixiv\\.net",
    "kyoko-np\\.net",
    "kyoko-janai\\.net",
    "youtu\\.be",
];
function isLinkAllowed(url) {
    return disallowedDomains.every(disallowed => !url.match(disallowed));
}

// Get the tweets where users are originally saying "虚構新聞"
export async function getKyokoTweets(since_id, limit = 100) {
    const res = await doRelatedSearch(since_id, limit);

    if (res.meta.result_count === 0) {
        // No results.
        return [];
    }

    const referencedUsers = toMap(res.includes.users || [], c => c.id);
    const referencedTweets = toMap(res.includes.tweets || [], c => c.id);

    // Given a tweet, get links referenced in them (possibly dereferencing mentioned tweets if there are any:
    // tweet -> url
    // tweet -> commented tweet (in referencedTweets) -> url
    function dereferenceMentionedLinks(tweet, referencedTweets) {
        if (!tweet.entities || !tweet.entities.urls) {
            return [];
        }
        let linkedUrls = [];
        tweet.entities.urls.forEach(url => {
            const referencedTweetId = idForTweet(url.expanded_url);
            if (!referencedTweetId) {
                // Not a mention of a tweet, so a direct link.
                linkedUrls.push({
                    ...url,
                    link: {
                        type: "directLink",
                    }
                });
                return;
            }
            // Mention of a tweet (which likely mentions a news article).
            const referencedTweet = referencedTweets[referencedTweetId];
            if (!referencedTweet) {
                // Tweet may be missing. This happens (eg tweet deleted)
                return;
            }
            if (!referencedTweet.entities || !referencedTweet.entities.urls) {
                // Tweet may not be mentioning a news article. happens.
                return;
            }
            referencedTweet.entities.urls.map(url => ({
                ...url,
                link: {
                    type: "commentOnTweet",
                    id: referencedTweetId,
                },
            }))
                .forEach(url => linkedUrls.push(url));
        });
        return linkedUrls;
    }
    function dereferenceRepliedLinks(tweet, referencedTweets) {
        if (!tweet.referenced_tweets || tweet.referenced_tweets.length === 0) {
            return [];
        }
        const repliedTo = tweet.referenced_tweets
            .filter(reference => reference.type === "replied_to");
        if (repliedTo.length === 0) {
            return [];
        }
        const referenced = referencedTweets[repliedTo[0].id];
        if (!referenced) {
            // can happen, if tweet is deleted.
            return [];
        }
        if (!referenced.entities || !referenced.entities.urls) {
            // can happen, if original tweet didn't have a link.
            return [];
        }
        return referenced.entities.urls.map(
            url => ({
                ...url,
                link: {
                    type: "replyOnTweet",
                    id: referenced.id,
                },
            })
        );
    }
    const tweets = res.data.map(tweet => {
        const mentionedUrls = dereferenceMentionedLinks(tweet, referencedTweets);
        const urlsInReply = dereferenceRepliedLinks(tweet, referencedTweets);
        let urls = []
            .concat(mentionedUrls)
            .concat(urlsInReply)
            // At this point, we don't want any internal links.
            .filter(url => !url.expanded_url.match(/twitter\.com/));
        // Ensure urls are unique
        const uniqueUrls = uniquify(urls, url => url.expanded_url);
        const user = referencedUsers[tweet.author_id];
        return {
            tweet: {
                ...tweet,
                tweetUrl: urlForTweet(tweet.id),
                user
            },
            links: uniqueUrls,
        };
    })
        // We don't care about tweets that don't have mentions of links.
        .filter(tweetWithLinks => tweetWithLinks.links.length > 0)
        .map(tweetWithLinks => ({
            tweet: tweetWithLinks.tweet,
            // Arbitrarily get the first one. We may want to look into a better
            // selection method at some point in the future.
            link: tweetWithLinks.links[0],
        }))
        // Early filter to ignore obvious uninteresting links.
        .filter(tweetWithLink => isLinkAllowed(tweetWithLink.link.expanded_url));


    function renderTwitterRecord(tweetAndLink) {
        const tweet = tweetAndLink.tweet;
        const link = tweetAndLink.link;
        const rendered = {
            id: tweet.id,
            createdAt: tweet.created_at,
            author: {
                id: tweet.author_id,
                userName: tweet.user.username,
            },
            text: tweet.text,
            link: {
                url: link.expanded_url,
                source: link.link,
            }
        };
        return rendered;
    }
    return tweets.map(renderTwitterRecord);
}
