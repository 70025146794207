import serialMap from '../utils/serial_map.js'
import chunkify from '../utils/chunkify.js'
import BaseDb from './base_db.js';

export default class BaseTweetDb extends BaseDb {
    constructor(firestoreDb) {
        super(firestoreDb);
    }

    // Overrides super.collection.
    get collection() {
        return this.db.collection("tweets");
    }

    getLatestTweet() {
        return this.doQuery({
            orderBy: this.buildOrderByClause("createdAt", "desc"),
            limit: 1,
        });
    }
    async getLatestTweetData() {
        return this.getLatestTweet().get().then(this.toData)
            .then(tweets => tweets.length > 0 ? tweets[0] : undefined);
    }

    getLatestTweets(spec = { limit: 10 }) {
        return this.doQuery({
            ...spec,
            orderBy: this.buildOrderByClause("createdAt", "desc"),
        });
    }
    async getLatestTweetsData(spec = { limit: 10 }) {
        return this.getLatestTweets(spec).get().then(this.toData);
    }

    async writeTweets(tweets) {
        return this.batchWrite(tweets, tweet => tweet.id);
    }
};
