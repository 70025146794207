import fs from 'fs';

const throwingFetcher = (id) => {
    return new Promise((resolve, reject) => {
        reject("Not implemented");
    });
};

export default class CachedMap {
    constructor(cachePath = "cache.json", fetcher = throwingFetcher) {
        this.cachePath = cachePath;
        this.cache = {};
        this.fetcher = fetcher;

        this.loadCache();
    }
    clearCache() {
        this.cache = {};
        this.exportCache();
    }
    exportCache() {
        fs.writeFileSync(this.cachePath, JSON.stringify(this.cache));
    }
    loadCache() {
        try {
            this.cache = JSON.parse(fs.readFileSync(this.cachePath));
        } catch (err) {
            this.cache = {};
        }
    }
    async getValue(id) {
        return this.get(id).then(value => value.value);
    }
    async get(id) {
        let value = this.cache[id];
        if (value) {
            return { value, cached: true };
        }
        return this.fetcher(id)
            .then(value => {
                this.cache[id] = value;
                this.exportCache();
                return { value, cached: false };
            });
    }
    get entries() {
        let keyValues = [];
        for (let id in this.cache) {
            keyValues.push({
                id,
                value: this.cache[id],
            });
        }
        return keyValues;
    }
}

