import serialMap from '../utils/serial_map.js'
import chunkify from '../utils/chunkify.js'

export default class BaseDb {
    constructor(firestoreDb) {
        this.db = firestoreDb;
    }

    get collection() {
        throw new Error("please implement");
    }

    toData(docs) {
        let data = [];
        docs.forEach(doc => data.push(doc.data()));
        return data;
    }

    buildWhereClause(lhs, operator, rhs) {
        return { lhs, operator, rhs };
    }
    buildOrderByClause(field, direction) {
        return { field, direction };
    }

    doQuery(spec) {
        // console.log(spec);
        let q = this.collection;
        if (spec.where) {
            spec.where.forEach(({ lhs, operator, rhs }) => {
                // console.log(lhs, operator, rhs);
                q = q.where(lhs, operator, rhs);

            });
        }
        if (spec.orderBy) {
            q = q.orderBy(spec.orderBy.field, spec.orderBy.direction);
        }
        if (spec.startAfter) {
            q = q.startAfter(spec.startAfter);
        } else if (spec.startAt) {
            q = q.startAt(spec.startAt);
        } else if (spec.endAt) {
            q = q.endAt(spec.endAt);
        } else if (spec.endBefore) {
            q = q.endBefore(spec.endBefore);
        }
        if (spec.limit) {
            q = q.limit(spec.limit);
        } else if (spec.limitToLast) {
            q = q.limitToLast(spec.limitToLast);
        }
        return q;
    }

    async batchWriteChunk(chunk, docRefFunc) {
        const batch = this.db.batch();
        chunk.forEach(element => {
            const docRef = this.collection.doc(docRefFunc(element));
            batch.set(docRef, element, { merge: true });
        })
        return batch.commit();
    }

    async batchWrite(elements, docRefFunc) {
        return serialMap(
            chunkify(elements, 50),
            chunk => this.batchWriteChunk(chunk, docRefFunc)
        );
    }
};
