import * as automod from "./automod/automod.js";
// export { automod };

import * as models from "./models/index.js";
export { models };

import * as twitter from "./twitter/fetcher.js";
// export { twitter };

import * as utils from "./utils/index.js";
// export { utils };

const version = "1.0.2";

export default {
    automod,
    models,
    twitter,
    utils,
    version
};
