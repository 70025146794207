export function computePoints(article) {
    let points = 0;
    if (article.hatena) {
        points = article.hatena.points;
    }
    if (article.tweets) {
        points += article.tweets.length;
    }
    return points;
}

export function kyokoScore(article) {
    const points = computePoints(article);
    const c1 = 0.1;
    const c2 = 3;
    // https://docs.google.com/spreadsheets/d/15wcMkWUvFVhOJZPGiYQdRbRiPC-9xwuSktgeycdYw5s/edit#gid=0
    const score = Math.floor(100 * 1 / (1 + Math.exp(-c1 * (points - c2))));
    return score;
}

export function autoModArticle(article) {
    function markAutoMod(article, type, reason) {
        return {
            ...article,
            autoModerationResult: {
                type,
                reason,
            }
        };
    }
    let points = computePoints(article);

    // Do we have enough evidence?
    if (points >= 3) {
        return markAutoMod(article, "ACCEPT", "");
    }
    return markAutoMod(article, "REJECT", "no evidence");
}
