
export default function chunkify(array, numPerChunk) {
    let chunks = [];

    let i = 0;
    while (i < array.length) {
        let chunk = [];
        for (let chunkIdx = 0; chunkIdx < numPerChunk && i < array.length; chunkIdx++, i++) {
            chunk.push(array[i]);
        }
        chunks.push(chunk);
    }
    return chunks;
}
