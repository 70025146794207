import './App.css';

import moment from "moment-timezone";
import React, { useState, useContext, useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { Button, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GoogleAds from 'react-google-ads'

import imageproxyclient from "imageproxyclient";
import EditableText from "./editable_text.js";
import articleDb from "../models/article_db.js";
import { ToastContext } from "./toast.js";
import common from "kyokocommon";

function formatDate(dateStr) {
    return moment(dateStr).tz("Asia/Tokyo").format("YYYY/MM/DD");
}
function formatDateTime(dateStr) {
    return moment(dateStr).tz("Asia/Tokyo").format("YYYY/MM/DD hh:mm:ss");
}
function currentTime() {
    return moment().tz("Asia/Tokyo").format();
}
function sanitizeComment(comment) {
    comment = comment.replace(/(<[^>]*>)/g, "");
    comment = comment.replace(/&quot;/g, "\"");
    return comment;
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            display: "inline",
        },
    },
}));

function nameForVerdict(verdictType) {
    return verdictType === "ACCEPT" ? "承認" : "却下";
}
const ConfirmVerdictDialog = ({ dialogState }) => {
    const verdictName = nameForVerdict(dialogState.verdictType);
    return <Dialog
        open={dialogState.show}
        onClose={dialogState.closeHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{`本当に${verdictName}を実行しますか？`}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                この操作は取り消せません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={dialogState.closeHandler} color="primary" autoFocus>
                {`やっぱなにもしない`}
            </Button>
            <Button onClick={dialogState.acceptHandler} color="secondary" autoFocus>
                {`本当に${verdictName}を実行する`}
            </Button>
        </DialogActions>
    </Dialog>
};

const VerdictPane = ({ docKey, verdict }) => {
    const classes = useStyles();

    const showToast = useContext(ToastContext);
    const saveVerdict = (newVerdictType) => {
        const newVerdict = {
            ...verdict,
            type: newVerdictType,
            timeOfVerdict: currentTime(),
        };
        // WARNING: this actually sends out a tweet.
        /**/
        articleDb.updateVerdict(docKey, newVerdict).then(res => {
            showToast(nameForVerdict(newVerdictType) + "しました", "success");
            return res;
        }
        ).catch(err => showToast(err.message, "error"));
        /**/
    };

    const [confirmationDialogState, setShowConfirmationDialogState]
        = useState({ show: false });
    const showConfirmationDialog = (verdictType) => {
        setShowConfirmationDialogState({
            show: true,
            verdictType,
            acceptHandler: () => {
                setShowConfirmationDialogState({ show: false });
                saveVerdict(verdictType);
            },
            closeHandler: () => setShowConfirmationDialogState({ show: false }),
        });
    }

    const submitComment = (comment) => {
        const newVerdict = {
            ...verdict,
            comment: comment,
        };
        articleDb.updateVerdict(docKey, newVerdict).then(res => {
            showToast("コメントを保存しました");
            return res;
        }).catch(err => showToast(err.message, "error"));
    };

    return <div className="Verdict">
        {verdict.type === "NONE" ?
            (
                <div className={classes.root}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => showConfirmationDialog("ACCEPT")}>承認</Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => showConfirmationDialog("REJECT")}>却下</Button>
                </div>
            ) :
            <div className="VerdictControl">{verdict.type === "ACCEPT" ? "承認済み" : "却下済み"} {formatDateTime(verdict.timeOfVerdict)}</div>}
        <EditableText className="VerdictCommentPane" onSubmit={(c) => submitComment(c, showToast)} defaultValue={verdict.comment}>コメント：</EditableText>
        <ConfirmVerdictDialog dialogState={confirmationDialogState}></ConfirmVerdictDialog>
    </div >;
}

const HatenaComment = ({ comment }) => {
    return <Paper className="Comment" elevation={2}>
        <div className="CommentStars">
            ⭐️ {comment.stars ? comment.stars.yellow : 0}
        </div>
        <div className="CommentTextAndAuthor">
            <div className="CommentText">{sanitizeComment(comment.text)}</div>
            <div className="CommentAuthor">
                {comment.userName} <a href={comment.commentUrl} target="_blank" rel="noreferrer">{formatDate(comment.created)}</a>
            </div>
        </div>
    </Paper>;
}

const TwitterComment = ({ comment }) => {
    const authorUrl = `http://twitter.com/${comment.userName}/status/${comment.id}`;
    const tweetUrl = `http://twitter.com/${comment.author.userName}/status/${comment.id}`;
    return <Paper className="Comment" elevation={2}>
        {/* <div className="CommentStars">
            ⭐️ {comment.stars ? comment.stars.yellow : 0}
        </div> */}
        <div className="CommentTextAndAuthor">
            <div className="CommentText">{sanitizeComment(comment.text)}</div>
            <div className="CommentAuthor">
                <a href={authorUrl} target="_blank" rel="noreferrer">@{comment.author.userName}</a> <a href={tweetUrl} target="_blank" rel="noreferrer">{formatDate(comment.createdAt)}</a>
            </div>
        </div>
    </Paper>;
}

const Comments = ({ isAdmin, hatenaComments, tweets }) => {
    const [numShowableComments, setNumShowableComments] = useState(3);

    let comments =
        hatenaComments.map(c => ({ date: c.created, hatena: c }));

    // Also show tweets (likely not ok: https://twitter.com/tos?lang=ja#updateintlContent)
    if (isAdmin && tweets) {
        comments = comments.concat(tweets.map(c => ({ date: c.createdAt, tweet: c })));
    }

    comments
        .sort((a, b) => a.date.localeCompare(b.date));
    return <div className="ArticleComments">
        {
            comments
                .slice(0, numShowableComments)
                .map(comment =>
                    comment.hatena ?
                        <HatenaComment key={"HatenaComment" + comment.hatena.commentUrl} comment={comment.hatena}></HatenaComment> :
                        <TwitterComment key={"TwitterComment" + comment.tweet.id} comment={comment.tweet}></TwitterComment>
                )
        }
        {numShowableComments < comments.length ?
            <Button onClick={() => setNumShowableComments(comments.length)}>コメントを全部見る({comments.length - numShowableComments}件)</Button>
            : ""}
    </div>;
}

const Article = ({ docKey, entry, isAdmin }) => {
    const verdict = entry.verdict;
    const metadata = entry.metadata;

    return <div className="Article">
        {isAdmin && <VerdictPane docKey={docKey} verdict={verdict} />}

        <div className="ArticleTitleAndImage">

            <div className="ArticleThumbDiv">
                {metadata.image && metadata.image.url ?
                    <img src={imageproxyclient.proxiedImage(metadata.image.url, 90, 90)} alt={metadata.title} className="ArticleThumb" />
                    : ""}
            </div>

            <div className="ArticleTitleAndDate">
                <div className="ArticleTitle">
                    <a href={metadata.url} target="_blank" rel="noreferrer">
                        {metadata.title}
                    </a>
                    {/* TODO: somehow get favicons! */}
                    {/* <img src={article.articleFavicon} alt="tmp" /> */}
                </div>
                <div className="ArticleDate">
                    {isAdmin ?
                        <a href={`/article/${docKey}`} alt="leaf" target="_blank" rel="noreferrer">{formatDate(metadata.date)}</a>
                        : formatDate(metadata.date)
                    }
                </div>
            </div>
        </div>
        <div>
            虚構度: {common.automod.kyokoScore(entry)}
        </div>

        <Comments isAdmin={isAdmin} hatenaComments={entry.hatena ? entry.hatena.comments : []} tweets={entry.tweets} />
    </div >
}

const Articles = ({ articles, isAdmin }) => {
    return <div>
        {articles.map(doc => {
            const key = doc.id;
            const entry = doc.data();
            return <Article key={key} docKey={key} entry={entry} isAdmin={isAdmin}></Article>;
        })}</div>;
}

const ArticlesPane = ({ isAdmin, articlePage }) => {
    const numPerPage = 25;
    const [numToLoad, setNumToLoad] = useState(numPerPage);
    const querySpec = { limit: numToLoad };

    let articleQuery;
    switch (articlePage) {
        case "allTweeted":
            articleQuery = articleDb.getArticlesAlreadyTweeted(querySpec);
            break;
        case "needsVerdict":
            articleQuery = articleDb.getArticlesWaitingVerdict(querySpec);
            break;
        case "allArticles":
            articleQuery = articleDb.getAllArticles(querySpec);
            break;
        default:
            articleQuery = articleDb.getArticlesAlreadyTweeted(querySpec);
    }
    const [entries, loading] = useCollection(articleQuery);
    const [cachedEntries, setCachedEntries] = useState();
    useEffect(() => {
        if (loading) {
            return;
        }
        setCachedEntries(entries);
    }, [entries, loading, cachedEntries]);

    if (!cachedEntries) {
        return <div>Loading...</div>;
    }
    const shouldShowMoreButton = numToLoad === cachedEntries.docs.length;

    return <div className="ArticlesPane">
        {isAdmin &&
            <GoogleAds
                client={process.env.REACT_APP_ADSENSE_CLIENT}
                slot={process.env.REACT_APP_ADSENSE_SLOT}
                className="adsbygoogle"
                format="auto"
                style={{ display: 'block' }}
            />}
        <Articles articles={cachedEntries.docs} isAdmin={isAdmin} />
        {shouldShowMoreButton && <div className="ShowMoreButtonDiv">
            <Button className="ShowMoreButton"
                variant="contained"
                onClick={
                    () => {
                        setNumToLoad(numToLoad + numPerPage);
                    }
                }>Load more!</Button>
        </div>}
        <br />
    </div >;
}
export default ArticlesPane;
