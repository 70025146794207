import serialMap from '../utils/serial_map.js'
import chunkify from '../utils/chunkify.js'
import BaseDb from './base_db.js';
import { toMap } from '../utils/arrays.js';

export default class BaseArticleDb extends BaseDb {
    // Overrides super.collection.
    get collection() {
        return this.db.collection("articles");
    }

    getTweetableArticles(spec = { limit: 100 }) {
        const mergedSpec = {
            ...spec,
            where: [
                this.buildWhereClause("autoModerationResult.type", "==", "ACCEPT"),
                this.buildWhereClause("verdict.type", "==", "ACCEPT"),
                this.buildWhereClause("delivery.tweetStatus", "==", "NONE"),
            ],
            orderBy: this.buildOrderByClause("metadata.date", "desc"),
        }
        return this.doQuery(mergedSpec);
    }
    async getTweetableArticlesData(spec = { limit: 100 }) {
        return this.getTweetableArticles(spec).get().then(this.toData);
    }
    getTweetableArticlesCursor(doc) {
        return doc.data().metadata.date;
    }

    getArticlesWaitingVerdict(spec = { limit: 100 }) {
        const mergedSpec = {
            ...spec,
            where: [
                this.buildWhereClause("autoModerationResult.type", "==", "ACCEPT"),
                this.buildWhereClause("verdict.type", "==", "NONE"),
                this.buildWhereClause("delivery.tweetStatus", "==", "NONE"),
            ],
            orderBy: this.buildOrderByClause("metadata.date", "asc"),
        }
        return this.doQuery(mergedSpec);
    }
    async getArticlesWaitingVerdictData(spec) {
        return this.getArticlesWaitingVerdict(spec).get().then(this.toData);
    }
    getArticlesWaitingVerdictCursor(doc) {
        return doc.data().metadata.date;
    }

    getAllArticles(spec = { limit: 100 }) {
        const mergedSpec = {
            ...spec,
            orderBy: this.buildOrderByClause("metadata.date", "desc"),
        }
        return this.doQuery(mergedSpec);
    }
    async getAllArticlesData(spec) {
        return this.getAllArticles(spec).get().then(this.toData);
    }
    getAllArticlesCursor(doc) {
        return doc.data().metadata.date;
    }

    getArticlesAlreadyTweeted(spec = { limit: 100 }) {
        const mergedSpec = {
            ...spec,
            where: [
                this.buildWhereClause("autoModerationResult.type", "==", "ACCEPT"),
                this.buildWhereClause("verdict.type", "==", "ACCEPT"),
                this.buildWhereClause("delivery.tweetStatus", "==", "SENT"),
            ],
            orderBy: this.buildOrderByClause("delivery.timeDelivered", "desc"),
        }
        return this.doQuery(mergedSpec);
    }
    async getArticlesAlreadyTweetedData(spec = { start: 0, limit: 100 }) {
        return this.getArticlesAlreadyTweeted(spec)
            .get()
            .then(this.toData);
    }
    getArticlesAlreadyTweetedCursor(doc) {
        return doc.data().delivery.timeDelivered;
    }

    // TODO: deprecate.
    createFirebaseKey(url) {
        let base64 = Buffer.from(url, "utf-8").toString("base64");
        return base64.replace(/\//g, "@");
    }
    docKey(article) {
        return this.createFirebaseKey(article.metadata.url);
    }

    async batchMergeArticleChunk(articleChunk, mergeFunc) {
        return this.db.runTransaction(async (t) => {
            const docRefs = articleChunk
                .map(a => this.createFirebaseKey(a.metadata.url))
                .map(k => this.collection.doc(k));
            // Fetch existing.
            const existingDocs = await t.getAll(...docRefs);
            const existingEntryForKey =
                toMap(
                    existingDocs
                        // Filter out docs that don't have data.
                        .filter(doc => doc.data()),
                    /* keyFunc= */ doc => doc.id,
                    /* valueFunc= */ doc => doc.data());

            return serialMap(
                articleChunk,
                async providedArticle => {
                    const key = this.createFirebaseKey(providedArticle.metadata.url);
                    const docRef = this.collection.doc(key);
                    const existingArticle = existingEntryForKey[key];

                    const mergedArticle = mergeFunc(existingArticle, providedArticle);
                    // Do the write.
                    await t.set(docRef, mergedArticle, { merge: true });

                    return {
                        existingArticle,
                        exportedArticle: mergedArticle,
                    };
                }
            );
        });
    }

    // Merge the provided articles with those already in storage, and
    // write the merged articles into storage. mergeFunc takes the existing and
    // provided articles, and is expected to return a merged article.
    async batchMergeUpdates(articles, mergeFunc) {
        const results = await serialMap(
            chunkify(articles, 50),
            chunk => this.batchMergeArticleChunk(chunk, mergeFunc)
        );
        return results.reduce((accum, c) => accum.concat(c), []);
    }

    async updateVerdict(docKey, newVerdict) {
        return this.collection.doc(docKey)
            .update({
                verdict: newVerdict,
            });
    }

    async updateDelivery(docKey, newDelivery) {
        return this.collection.doc(docKey)
            .update({
                delivery: newDelivery
            });
    }
};
