
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import imageproxyclient from "imageproxyclient";
import articleDb from "../models/article_db.js";

const ArticleLeafPage = ({ articleId }) => {
    const ref = articleDb.collection.doc(articleId);
    const [article, loading, error] = useDocumentDataOnce(ref);
    if (!article || loading) {
        return <div>Loading..</div>;
    }
    if (error) {
        return <div>{error}</div>;
    }

    return <div>
        {article.metadata.url}
        {article.metadata.title}
        {article.metadata.description}
        <div>
            <img src={imageproxyclient.proxiedImage(article.metadata.image.url, 200, 200)} alt={article.metadata.title} />
        </div>
    </div>
};
export default ArticleLeafPage;