
export function clearUndefined(map) {
    return dictFilter(map, (k, v) => (typeof v !== "undefined"));
}

export function dictFilter(map, kvFilter) {
    return Object.keys(map)
        .filter(k => kvFilter(k, map[k]))
        .reduce((accum, c) => {
            accum[c] = map[c];
            return accum;
        }, {});
}

// Just use Object.entries/toDict.
// class KeyValueStream {
//     constructor(dict) {
//         this.entries = Object.entries(dict);
//         this.operations = [];
//     }
//     commit() {
//         if (this.operations.length === 0) {
//             return;
//         }
//         this.operations.forEach(op => {
//             this.entries = op(this.entries);
//         });
//         this.operations = [];
//     }
//     filter(kvFilter) {
//         this.operations.push(
//             entries =>
//                 entries.filter(entry => kvFilter(entry[0], entry[1])));
//         return this;
//     }
//     // mapFunc: (key, value) => [newKey, newValue]
//     map(mapFunc) {
//         this.operations.push(
//             entries =>
//                 entries.map(entry => mapFunc(entry[0], entry[1]))
//         );
//         return this;
//     }
//     mapValue(mapFunc) {
//         return this.map((k, v) => [k, mapFunc(k, v)]);
//     }
//     mapKey(mapFunc) {
//         return this.map((k, v) => [mapFunc(k, v), v]);
//     }
//     forEach(func) {
//         this.commit();
//         this.entries.forEach(([key, value]) => func(key, value));
//         return this;
//     }
//     toDict() {
//         this.commit();
//         return this.entries.reduce((accum, c) => {
//             accum[c[0]] = c[1];
//             return accum;
//         }, {});
//     }
// }

// export function toStream(dict) {
//     return new KeyValueStream(dict);
// }
export const toDict = (m, [k, v]) => { m[k] = v; return m; };
